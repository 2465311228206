import * as React from 'react';
import styles from './Button.module.css';

export type ButtonProps = {
    text: string;
    icon: string;
    theme?: string;
    onClick: Function;
}
const Button = ( props: ButtonProps ) => {
    const { icon, text, theme, onClick } = props;
    let btnTheme = styles.rlscButton__primary;
    switch ( theme ) {
        case 'primary':
            btnTheme = styles.rlscButton__primary;
            break;
        case 'secondary':
            btnTheme = styles.rlscButton__secondary;
            break;
        case 'empty':
            btnTheme = styles.rlscButton__empty;
            break;
    }
    const rootClass = `${styles.rlscButton} ${btnTheme}`;
    function getIcon () {
        if ( icon ) {
            const iconClass = `fa fa-${icon} ${styles.rlscButton__icon}`;
            return <i className={iconClass}></i>;
        } 
    }
    function btnClick ( event: any ) {
        console.log ( 'btn click called');
        event.preventDefault();
        onClick ();
    }
     return (
        <button onClick={btnClick} className={rootClass}>
            <div className={styles.rlscButton__title}>
                <span>{text}</span>
                { getIcon ()}
            </div>
        </button>
     )
};

export default Button;