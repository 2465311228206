import { createBrowserRouter } from "react-router-dom";
import ComingSoon from "./ComingSoon";

const router = createBrowserRouter([
      { path: "/", element: <ComingSoon></ComingSoon> },
      { path: "/home", element: <ComingSoon></ComingSoon> }
    ]
    // , { basename: "/portal" }
);

export default router;