import React from "react";
import styles from './LoadMask.module.css';
const LoadMask = () => {
    
    return (
        <div className={styles.rlscLoadMaskHost}>
            <div className={styles.rlscLoadMaskTitle}>Loading...</div>
        </div>
    )
};

export default LoadMask;