import * as React from 'react';
import styles from './Footer.module.css';
import LinkButton from './../LinkButton';
import { realstack_icon_inverted } from '../../assets';

export default function Footer ( ) {
    return (
        <div className={styles.rlscFooter}>
                <a href="/" className={styles.rlscFooter__title}>
                    <img  className={styles.rlscFooter__title__logo} src={realstack_icon_inverted}/>
                    <div className={styles.rlscFooter__spacer}></div>
                    <div className={styles.rlscFooter__subtitle} >Australia's first tokenized property marketplace</div>
                </a>
                <div className={styles.rlscFooter__cnt}>
                    <div className={styles.rlscFooter__section}>
                        <div className={styles.rlscFooter__section__title}>realstack.io</div>
                        <LinkButton  theme="disabled" href="/about" text='About Us'></LinkButton>
                        <LinkButton  theme="disabled" href="/contact" text='Contact Us'></LinkButton>
                        <LinkButton  theme="disabled" href="/partner" text='Partner Program'></LinkButton>
                        <LinkButton  theme="disabled" href="/affiliate" text="Affiliate Program"></LinkButton>
                        <LinkButton  theme="disabled" href="/developer" text="Developer Program"></LinkButton>
                    </div>
                    <div className={styles.rlscFooter__section}>
                        <div className={styles.rlscFooter__section__title}>Product</div>
                        <LinkButton  theme="disabled" href="/product" text="Product Overview"></LinkButton>
                        <LinkButton  theme="disabled" href="/start" text="Privacy Policy"></LinkButton>
                        <LinkButton  theme="disabled" href="/move" text="Terms and Conditions"></LinkButton>
                        <LinkButton  theme="disabled" href="/integrations" text="Integrations"></LinkButton>
                        <LinkButton  theme="disabled" href="/pricing" text="Pricing"></LinkButton>
                    </div>
                    <div className={styles.rlscFooter__section}>
                        <div className={styles.rlscFooter__section__title}>Resources</div>
                        <LinkButton  theme="disabled" href="/help" text="Help Center"></LinkButton>
                        <LinkButton  theme="disabled" href="/guides" text="Guides"></LinkButton>
                        <LinkButton  theme="disabled" href="/learn" text="Learn"></LinkButton>
                        <LinkButton  theme="disabled" href="/freetools" text="Free Tools"></LinkButton>
                    </div>
                </div>
        </div>

    )
};