import { gql } from "@apollo/client";

export const SUBSRCIBE_MUTATION = gql`
  mutation Subsrcibe ($fullName:String!, $emailAddress:String!, $phoneNumber:String){
    subscribe (fullName: $fullName, emailAddress: $emailAddress,phoneNumber: $phoneNumber ) {
        token,
        message,
        error
    }
}
`;