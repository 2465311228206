import * as React from 'react';
import styles from './ComingSoon.module.css';
import RegisterInterestForm from '../../components/RegisterInterestForm';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
export default function ComingSoon () {
//   const { data, error, loading } = useLaunchListQuery();
//   if (loading) {
//     return <div>Loading...</div>;
//   }
//   if (error || !data) {
//     return <div>ERROR</div>;
//   }
     return (
        <div className={styles.rlscLogin}>
            <Header hideControls={true} headerTitle='Realstack.io' subTitle=''></Header>
            <div className={styles.rlscLoginFormHost}>
                <RegisterInterestForm/>
                <Footer></Footer>
            </div>
        </div>
     )
};

