import React, { useState } from "react";
import Button from "../Button";
import styles from "./RegisterInterestForm.module.css";
import { realstack_icon_inverted, realstack_icon_growth, realstack_icon_txt, realstack_icon_no_txt } from '../../assets';
import { SUBSRCIBE_MUTATION } from "./RegisterInterestForm.gql";
import { useApolloClient } from "@apollo/client";
import LoadMask from "../LoadMask";

const RegisterInterestFormForm = () => {
    /** state variables */
    const [errorMessages, setErrorMessages] = useState({ 
        fullName: { error: '', field: 'Full Name',message: 'Please provide your full name'}, 
        emailAddress: { error: '', field: 'Email Address',message: 'Please provide your email address'},
        phoneNumber: { error: '', field: 'Phone Number',message: 'Please provide your phone number'},
    });
    const [interestForm, setInterestForm] = useState({ fullName: '', emailAddress: '', phoneNumber: '' });
    const [isLoading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [subsrcibed, setSubsrcibed] = useState(false);
    const [isError, setError] = useState(false);
    /** functional variables */
    const client = useApolloClient()
    const validation: any = { 
      emailAddress:  new RegExp(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/),
      fullName: new RegExp ( /^[a-zA-Z ]{2,100}$/ ),
      phoneNumber: new RegExp ( /^$|^[0-9+]{9,13}$/ )
    }
    const s = validation.phoneNumber.test('')
    const handleSubmit = async () => {
      const { fullName, emailAddress, phoneNumber } = interestForm;
        let valid = validateInput ( { target: {name: 'fullName', value: fullName}}) 
                    && validateInput ( {target: {name: 'emailAddress', value: emailAddress}})&& 
                    validateInput ( {target: { name: 'phoneNumber', value: phoneNumber }});
          console.log ( fullName, validateInput ( { target: {name: 'fullName', value: fullName}})  )
          console.log ( emailAddress, validateInput ( { target: {name: 'emailAddress', value: emailAddress}})  )
          console.log ( phoneNumber, validateInput ( { target: {name: 'phoneNumber', value: phoneNumber}})  )
        if ( valid ) {
          setLoading ( true )
          try {
              const variables = { fullName, emailAddress, phoneNumber };
              const { data } = await client.mutate ( { mutation: SUBSRCIBE_MUTATION, variables })
              setLoading ( false )
              if ( data ) {
                  const { subscribe: { error, token, message } } = data;
                  if ( error ) {
                    throw ( message )
                  }
                  setLoading ( false )
                  setError ( false );
                  setResponseMessage( `${message}` );
                  setInterestForm ({ fullName: '', emailAddress: '', phoneNumber: '' });
              } else {
                throw ('Something went wrong try again')
              }
          } catch (error) {
              setError ( true );
              setResponseMessage( `${error}` );
          } finally {
            setLoading ( false );
            setSubsrcibed ( true );
          }
        } else {
          alert ( 'Please enter valid details')
        }
    };
    const cancelClick = () => {
        window.location.href = "/portal/";
    };
    function handleInputChange(event: any) {
        const target = event.target;
        const { name, value } = target;
        const state: any = { ...interestForm };
        state[name] = value;
        setInterestForm(state);
    }
    function validateInput (event: any) {
        const target = event.target;
        const { name, value } = target;
        const state: any = { ...interestForm };
        const errorMessagesState: any = { ...errorMessages };
        let valid = false;
        if ( validation[name].test ( value ) ) {
          state[name] = value;
          errorMessagesState[name].error = '';
          valid = true;
        } else {
          errorMessagesState[name].error = `Please enter valid ${errorMessagesState[name].field}`;
          setErrorMessages (errorMessagesState);
        }
        setInterestForm(state);
        return valid;
    }
    // Generate JSX code for error message
    function renderErrorMessage (name: 'fullName' | 'emailAddress' | 'phoneNumber' ) {
      const {message, error} = errorMessages[name]
      if ( error ) {
        return ( <label className={styles.errorText}>{error}</label> )
      } else {
        return ( <label className={styles.helperText}>{message}</label> )
      }
    }   
    function thankYou() {
        setSubsrcibed ( false );
        setResponseMessage ( '' );
    }
    function toRegister() {
        window.location.href = "/register";
    }
    return (
        <div className={styles.rlscFormHost}>
            <div className={styles.rlscBgPattern}></div>
            <div className={styles.rlscBgPattern1}></div>
          
            <div className={styles.rlscComingSoon}>
              <div className={styles.rlsc__comingSoon__title}>Great things coming soon...</div>
                <img  className={styles.rlsc__logo} src={realstack_icon_no_txt}/>
                <img  className={styles.rlsc__logo__1} src={realstack_icon_txt}/>
                <div className={styles.rlsc__comingSoon__desc}>In the very near future we will be launching Australia's first tokenized property marketplace.</div>
            </div>
            { !subsrcibed && <div className={styles.rlscRegisterInterestForm}>
              { isLoading && <LoadMask></LoadMask> }
                <div className={styles.rlscFormTitle}>
                We are almost ready to launch! 
                  <div>
                    <label className={styles.helperText}>
                    Be the first to know about RealStack’s asset tokenisation platform.
                    </label>
                  </div>
                </div>
                <form className={styles.rlscFormCnt} onSubmit={handleSubmit}>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>*Full Name</label>
                        <input
                          className={styles.inputText}
                          value={interestForm.fullName}
                          name="fullName"
                          onChange={handleInputChange}
                          onBlur={validateInput}
                          type="text"
                          required/>
                        {renderErrorMessage("fullName")}
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>*Email Address<sup></sup></label>
                        <input
                          className={styles.inputText}
                          value={interestForm.emailAddress}
                          name="emailAddress"
                          onChange={handleInputChange}
                          onBlur={validateInput}
                          type="text"
                          required
                        />
                        {renderErrorMessage("emailAddress")} 
                    </div>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputLabel}>Phone Number<sup></sup></label>
                        <input
                          className={styles.inputText}
                          value={interestForm.phoneNumber}
                          name="phoneNumber"
                          onChange={handleInputChange}
                          onBlur={validateInput}
                          type="text"
                          required
                        />
                        {renderErrorMessage("phoneNumber")} 
                    </div>
                    <div className={styles.rlscFormBtnHost}>
                        <Button
                          onClick={handleSubmit}
                          text="Subscribe"
                          theme="primary"
                          icon="arrow-right"></Button>
                    </div>  
                    <div className={styles.rlsc__spacer}></div>
                </form>
                <img  className={styles.rlscRegisterInterestForm_img} src={realstack_icon_growth}/>
            </div> }
            { subsrcibed && <div className={styles.rlscRegisterInterestForm}>
                { !isError && <div className={styles.rlscFormTitle}>
                      <div>Thanks for registering with us!</div>
                      <div className={styles.subTitleFormText}>{responseMessage}</div>
                      <div className={styles.responseText}>
                        We’re glad you have shown interest in RealStack’s asset tokenisation platform.
                        Over the next few days, our team will reach out to you to set up a personalised 
                        discussion to understand your goals and explain how you can maximise your potential in the real estate market.
                      </div>
                </div> }
                { isError && <div className={styles.rlscFormTitle}>
                    <div>Failed to Subscribe!</div>
                    <div className={styles.subTitleFormText}>{responseMessage}</div>
                </div> }
                <div className={styles.thankYouContainer}>
                    <div className={styles.rlscFormBtnHost}>
                        <Button
                          onClick={thankYou}
                          text={isError ? 'Try Again' : 'Thank You!'}
                          theme={isError ? 'secondary' : 'primary'}
                          icon={isError ? 'times' : 'check'}></Button>
                    </div>  
                    <div className={styles.rlsc__spacer}></div>
                </div>
                <img  className={styles.rlscRegisterInterestForm_img} src={realstack_icon_growth}/>
            </div> }
        </div>
    );
};

export default RegisterInterestFormForm;
