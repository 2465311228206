import { gql, useApolloClient } from '@apollo/client';
import React, { useState, useEffect } from "react";
import { realstack_icon } from '../../assets';
import LinkButton from '../LinkButton';
import LoadMask from '../LoadMask';
import Button from './../Button';
import styles from './Header.module.css';

const USER_QUERY = gql`
  query User {
    user {
        firstName
        lastName
        emailAddress
        phoneNumber
    }
  }
`;
type HeaderProps = {
    headerTitle: string;
    subTitle: string;
    hideControls?: boolean;
}
const Header = ( props: HeaderProps ) => {
    const { headerTitle, subTitle, hideControls } = props
    const [isLoading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        error: true
    });
    
    
    function singup () {
        alert ( 'Coming Soon!')
    }
    
    return (
        <div className={styles.rlscHeaderHost}>
            <div className={styles.rlscHeader}>
                <a href="/" className={styles.rlscHeader__title}>
                    <img  className={styles.rlscHeader__title__logo} src={realstack_icon}/>
                </a>
                <div className={styles.rlscHeader__options}>
                <div className={styles.rlscHeader__spacer}></div>
                    <LinkButton theme="disabled" href="/about" text='Marketplace'></LinkButton>
                    <LinkButton theme="disabled" href="/about" text='Learn'></LinkButton>
                    <LinkButton theme="disabled" href="/about" text='Contact Us'></LinkButton>
                    <LinkButton theme="disabled" href="/about" text='About Us'></LinkButton>
                    <Button onClick={singup} text="Sign Up" theme="primary" icon="arrow-right"></Button>

                </div>
                
            </div>
        </div>
    )
};

export default Header;