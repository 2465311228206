import * as React from 'react';
import styles from './LinkButton.module.css';

export type ButtonProps = {
    text: string;
    href: string;
    theme?: string;
}
const LinkButton = ( props: ButtonProps ) => {
    const { href, text, theme } = props;
    function singup () {
        alert ( 'Coming Soon!')
    }
    let btnTheme = styles.rlscLinkButton;
    let btnTitleStyle = styles.rlscLinkButton__title;
    switch ( theme ) {
        case 'primary':
            btnTheme = styles.rlscLinkButton__primary;
            btnTitleStyle = styles.rlscLinkButton__primary__title;
            break;
        case 'secondary':
            btnTheme = styles.rlscLinkButton__secondary;
            btnTitleStyle = styles.rlscLinkButton__secondary__title;
            break;
        case 'disabled':
            btnTheme = styles.rlscLinkButton__disabled;
            btnTitleStyle = styles.rlscLinkButton__disabled__title;
            break;
    }
    let rootClass = `${styles.rlscLinkButton} ${btnTheme}`;
    let titleClass = `${styles.rlscLinkButton__title} ${btnTitleStyle}`;
    return (
        // <div className={styles.rlscLinkButtonHost}>
        //     <a href={href} className={styles.rlscLinkButton}>
        //         <div className={styles.rlscLinkButton__title}>{text}</div>
        //     </a>
        // </div>
        <div className={styles.rlscLinkButtonHost}>
            <span className={rootClass}>
                <div className={titleClass}>{text}</div>
            </span>
        </div>
     )
};

export default LinkButton;